/* globals jQuery, wordpress_globals */

import "../sass/theme.scss";

import $ from 'jquery';
import svgxhr from 'webpack-svgstore-plugin/src/helpers/svgxhr';
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

window.riversideadvisory = {
    init: function () {
        this.helpers.initSvg();

        //Allow user to interrupt auto-scrolling
        $(document).bind('scroll mousedown wheel keyup', function (e) {
            if (e.which || e.type === 'mousedown' || e.type === 'mousewheel') {
                $(document.scrollingElement).stop();
            }
        });
    },
    helpers: {
        requireAll: function (r) {
            r.keys().forEach(r);
        },
        initSvg: function () {
            svgxhr({
                filename: './wp-content/themes/class/dist/sprite.svg', //URL to fetch
            });
        }

    }

    //Prefer creating new modules when appropriate, rather than adding functions here
    //If you need something to be globally accessible, you can assign an imported module to this namespace
    //I.e import Contact from './contact';
    //    window.namespace.Contact = Contact;
};

window.riversideadvisory.init();


if (typeof NodeList.prototype.forEach !== 'function') {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

// SVG Sprite Loader
// window.riversideadvisory.helpers.requireAll(require.context('../images/icons/', true, /\.svg$/));
